
import Dashboard from "views/Dashboard.js";
import { CreatePe, ListPe, setUserPwd } from "views/Person";
import { CreateUPe, ListUPe } from "views/UserPerson";
import {ListCo, CreateCo} from "views/Company"
import { ListSu, CreateSu } from "views/Supplier"
import { ListSt, CreateSt } from "views/Stock"
import { ListPr, CreatePr } from "views/Product";
import {ListCP, CreateCP } from "views/CommercialProduct"
import { ListMV, CreateMV } from "views/Movement"
import { ListFP, CreateFP } from "views/FpFile";
import { ListCa, CreateCa } from "views/Call";
import {ListN, CreateN} from "views/Nf";

const nestedRoutes = [
  {
    path: "/userpersons/edit/:id?",
    name: "Cadastro de Usuários",
    component: CreateUPe,
    layout: "/manage",
    auth:"auth.change_user"
  },
  {
    path: "/companies/edit/:id?",
    name: "Cadastro de Empresas",
    component: CreateCo,
    layout: "/manage",
    auth:"operations.change_company"
  },
  {
    path: "/suppliers/edit/:id?",
    name: "Cadastro de Fornecedores",
    component: CreateSu,
    layout: "/manage",
    auth:"operations.change_supplier"
  },
  {
    path: "/stocks/edit/:id?",
    name: "Manter Estoque de Produtos controlados/comercializados",
    component: CreateSt,
    layout: "/manage",
    auth:"operations.change_stockedproduct"
  },
  {
    path: "/products/edit/:id?",
    name: "Cadastro de Produtos",
    component: CreatePr,
    layout: "/manage",
    auth:"operations.change_product"
  },
  {
    path: "/commercialproducts/edit/:id?",
    name: "Cadastro de Produtos Comercializados",
    component: CreateCP,
    layout: "/manage",
    auth:"operations.change_commercialproduct"
  },
  {
    path: "/movements/edit/:id?",
    name: "Cadastro de Ações",
    component: CreateMV,
    layout: "/manage",
    auth:"operations.add_movement"
  },
  {
    path: "/persons/edit/:id?",
    name: "Cadastro de perfis",
    component: CreatePe,
    layout: "/manage",
    auth:"operations.change_person"
  },
  {
    path: "/fpfiles/edit/:id?",
    name: "Gerar Arquivos",
    component: CreateFP,
    layout: "/manage",
    auth:"operations.change_fpfile"
  },
  {
    path: "/calls/edit/:id?",
    name: "Chamados",
    component: CreateCa,
    layout: "/manage",
    auth:"operations.change_call"
  },
  {
    path: "/nfs/edit/:id?",
    name: "Notas fiscais",
    component: CreateN,
    layout: "/manage",
    auth:"operations.change_nf"
  },
  {
    path: "/persons/updateuserpwd/:id?",
    name: "Atualizar Senha",
    component: setUserPwd,
    layout: "/manage",
    auth:"operations.change_person"
  },
]

const dashboardRoutes = [  
  {
    path: "/userpersons",
    name: "Usuários",
    icon: "nc-icon nc-circle-09",
    component: ListUPe,
    layout: "/manage",
    auth:"auth.view_user"
  },
  {
    path: "/companies",
    name: "Empresa Gestora",
    icon: "nc-icon nc-bank",
    component: ListCo,
    layout: "/manage",
    auth:"operations.view_company"
  },
  {
    path: "/suppliers",
    name: "Fornecedores",
    icon: "nc-icon nc-delivery-fast",
    component: ListSu,
    layout: "/manage",
    auth:"operations.view_supplier"
  },
  {
    path: "/products",
    name: "Produto Simples",
    icon: "nc-icon nc-atom",
    component: ListPr,
    layout: "/manage",
    auth:"operations.view_product"
  },
  {
    path: "/commercialproducts",
    name: "Produto Composto",
    icon: "nc-icon nc-tag-content",
    component: ListCP,
    layout: "/manage",
    auth:"operations.view_commercialproduct"
  },
  {
    path: "/movements",
    name: "Ações",
    icon: "nc-icon nc-puzzle-10",
    component: ListMV,
    layout: "/manage",
    auth:"operations.view_movement"
  },
  {
    path: "/stocks",
    name: "Estoque",
    icon: "nc-icon nc-ruler-pencil",
    component: ListSt,
    layout: "/manage",
    auth:"operations.view_stockedproduct"
  },
  {
    path: "/fpfiles",
    name: "Gerar Remessa",
    icon: "nc-icon nc-cloud-download-93",
    component: ListFP,
    layout: "/manage",
    auth:"operations.view_fpfile"
  },
  {
    path: "/calls",
    name: "Chamados",
    icon: "nc-icon nc-cloud-upload-94",
    component: ListCa,
    layout: "/manage",
    auth:"operations.view_call"
  },
  {
    path: "/nfs",
    name: "Retificar NF's",
    icon: "nc-icon nc-single-copy-04",
    component: ListN,
    layout: "/manage",
    auth:"operations.change_movement"
  },
  {
    path: "/persons",
    name: "Meu Perfil",
    icon: "nc-icon nc-single-02",
    component: ListPe,
    layout: "/manage",
    auth:"operations.view_person"
  },
 
];
function dropdown(){

}
export {
  dashboardRoutes, nestedRoutes
}

