import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getProducts } from "./ProductActions";
import { IMaskInput } from "react-imask";
import Product from "./Product"
import { cnpjMask, cnpjValidation, onlyNumber, densityMask} from "../../utils/mask"
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { formatDate } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Search } from 'react-bootstrap-icons';
import { getNCMGenerals } from "../ncmgeneral/NCMGeneralActions"
import { getSuppliers } from "components/supplier/SupplierActions";
import Loader from "components/utils/Loader"
import {
  Card,
  Form,
  Row,
  Col,
  Button
} from "react-bootstrap";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.props.getNCMGenerals()
    this.dt = React.createRef();
    this.state = {
      pd: {
        supplier: '',
        ncm_general: ''
      },
      enabled : true,
      loading:false,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ncm_general_code: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ncm_general_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ncm_synonym_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }
    }
  }

  componentDidMount() {
    this.props.getSuppliers();
    this.setState({ loading: true })    
    this.props.getProducts({is_message_to_user: false, enabled:true}).then((response) => {
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  cols = [
    { field: 'ncm_general_code', header: 'Código NCM' },
    { field: 'ncm_general_name', header: 'Nome NCM' },
    { field: 'commercial_name', header: 'Nome Comercial' },
    { field: 'density', header: 'Densidade' },
    { field: 'concentration', header: 'Concentração' },
    { field: 'supplier_name', header: 'Fornecedor' }
  ];

  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };
  
  handleSubmit = () => {
    this.setState({ loading: true })
    this.props.getProducts({is_message_to_user:false,supplier: this.state.pd.supplier, ncm_general: this.state.pd.ncm_general}).then(() => {
      this.setState({ loading: false })
    })
  }

  onChange = e => {
    var pd = { ...this.state.pd }
    this.setState({ loading: true })
    pd[e.target.name] = e.target.value
    pd['enabled'] = true
    this.props.getProducts(pd).then(() => {
      var pds = {...this.state}
      pds.loading = false
      pds.pd = pd
      this.setState(pds)
    })
    this.setState({ pd });
  };
  alocBodyTemplate = (rowData) => {
    return <Product key={rowData.id} product={rowData} modal={this.props.showModal}/>
  }

  render() {
    const { products } = this.props.productsReduc;
    const { ncmgenerals } = this.props.ncmgeneralsReduc
    const { suppliers } = this.props.suppliersReduc

    let suppliers_options = suppliers.map(sup => {
      return <option key={sup.id} value={sup.id}>{`${sup.corporate_name} | ${cnpjMask(sup.cnpj)}`}</option>
    })

    let ncmgenerals_options = ncmgenerals.map(c => {
      return <option key={c.id} value={c.id}>{ c.name + ' ' + c.code }</option>
    })
    
    let items = products.filter(item => item.enabled == true && this.props.supplier ? item.supplier== this.props.supplier : true).map(item => {
      item.enabled_virtual = item.enabled ? "Sim" : "Não"
      item.density = item.density.replace(".", ",")
      return item
    });
  
    if(this.props.showFilter == false){
      this.state.enabled = this.props.showFilter
    }
    else{
      this.state.enabled = true
    }

    return (
      <>
      <Loader loading={this.state.loading} />      
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Cadastro de Produtos (Substâncias / Resíduos) simples</Card.Title>
              <p className="card-category">
                Substâncias e Resíduos simples
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable ref={this.dt} value={items} header={(this.state.enabled && <div>
                  <Row className="mb-3"></Row>
                  <Row >
                    <Form.Group as={Col} md="4">
                      <Form.Label className="font-weight-bold" >Fornecedor</Form.Label>
                      <Form.Control
                        as="select" aria-label="supplier" name="supplier" onChange={this.onChange} value={this.state.pd.supplier}>
                        <option value="">--- SELECIONE ---</option>
                        {suppliers_options}
                      </Form.Control>
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Form.Label className="font-weight-bold" >NCM</Form.Label>
                      <Form.Control
                        as="select" required aria-label="NCM" name="ncm_general" onChange={this.onChange} value={this.state.pd.ncm_general}>
                        <option value="">--- SELECIONE ---</option>
                        {ncmgenerals_options}
                      </Form.Control>
                      <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    </Form.Group>
                    <Col>
                    </Col>
                
                    <Col >
                      {header(products, "Pessoas", this.dt, this.exportColumns)}
                    </Col>
                  </Row>
                </div>)}
                  tableStyle={{ minWidth: '50rem' }}
                  paginator rows={5}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  filterDisplay="row"
                  filters={this.state.filters}
                  emptyMessage="Não há produtos cadastradas"

                >
                  {this.cols.map((col, index) => (
                    <Column key={index} filter sortable field={col.field} header={col.header} />
                  ))}
                  <Column body={this.alocBodyTemplate}></Column>
                </DataTable>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}

ProductList.propTypes = {
  getProducts: PropTypes.func.isRequired,
  productsReduc: PropTypes.object.isRequired,
  suppliersReduc: PropTypes.object.isRequired,
  getSuppliers: PropTypes.func.isRequired,
  ncmgeneralsReduc: PropTypes.object.isRequired,
  getNCMGenerals: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  productsReduc: state.productsReduc,
  ncmgeneralsReduc: state.ncmgeneralsReduc,
  suppliersReduc: state.suppliersReduc
});

export default connect(mapStateToProps, {
  getProducts, getNCMGenerals, getSuppliers
})(withRouter(ProductList));