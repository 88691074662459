import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber, densityMask} from "../../utils/mask"
import { DateTime } from 'luxon';
import { addCommercialProduct, updateCommercialProduct, getCommercialProduct, modalAddCommercialProduct } from "./CommercialProductActions";
import Loader from "components/utils/Loader"
import { getNCMGenerals } from "../ncmgeneral/NCMGeneralActions"
import { getNCMSynonymsByNCMGeneral} from "../ncmsynonym/NCMSynonymActions"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties, toastOnError } from "../../utils/Utils";
import { InputNumber } from 'primereact/inputnumber';
import { TrashFill} from 'react-bootstrap-icons';
import ControlDensity from "components/utils/ControlDensity"
import ControlConcentration from "components/utils/ControlConcentration"
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";
import CommercialProduct from "./CommercialProduct";

// https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial

class AddCommercialProduct extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            el: {
                id:"",
                commercial_name: "",
                company: "",
                density: "",
                enabled:true
            },
            sps: [{                
                pr:{
                    id:"",
                    ncm_general: "",                    
                    concentration:"",                    
                    enabled:true
                },
            }],
            validated: false,
            loading: false
        }
        this.props.getNCMGenerals()
        this.props.commercialproductsReduc.commercialproduct = this.state.el        
        this.onChange = this.onChange.bind(this)
        this.onChangeNCMGeneral = this.onChangeNCMGeneral.bind(this)
    }

    setValidated = (v) => {
        this.setState({ validated: v });
    }
    onChangeDensity = e => {
        this.onChange(e)
    };
    handleSubmit = (event) => {
        const form = document.getElementById("AddCommercialProduct")

        if (this.state.el.density[0] === ","){
            toastOnError("Utilize vírgula apenas como separador decimal")
            this.setValidated(false);
            form.checkValidity()
            return
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();            
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.commercialproductsReduc.commercialproduct.id !== prevState.el.id) {
            var { commercialproduct } = nextProps.commercialproductsReduc;
            commercialproduct = putBlankProperties(commercialproduct)
            if (commercialproduct.id){
                var st = AddCommercialProduct.parser(commercialproduct)
                return {
                    el:st.el,
                    sps:st.sps,
                    validated: prevState.validated
                };
            }
            else
                return null
        }
        else
            return null
    }

    static parser(commercialproduct){
        var st = {}
        st.el = {...commercialproduct}
        st.el.density = densityMask(st.el.density)
        st.sps = []
        if (commercialproduct.substances)
            commercialproduct.substances.map((e, index) => {
                e.concentration = onlyNumber(e.concentration)
                st.sps.push({pr: e})
            }
        )
        delete st.el.substances
        return st
    }

    componentDidMount() {
        if (!this.props.isModalAddCommercialProduct){
            const { id } = this.props.match.params;
            if (id != null) {
                this.setState({loading:true})

                this.props.getCommercialProduct(id).then(() => {                    
                    var st = AddCommercialProduct.parser(this.props.commercialproductsReduc.commercialproduct)                    
                    this.setState({el: st.el, sps: st.sps, loading:false})
                })
            }
            else {
                this.props.commercialproductsReduc.commercialproduct = this.state.el
            }
        }
        else{            
            var el = this.props.commercialproductsReduc.commercialproduct
            this.setState({el})            
        }
    }

     onChangeCheckbox = e => {        
        var el = { ...this.state.el }        
        el[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ el });
    }

    onChangeNCMGeneral = e => {
        this.onChange(e)
        this.setState({loading:true})
        this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
            this.setState({loading:false})
            var el = { ...this.state.el }
            el['ncm_synonym'] = ""
            this.setState({ el });
        })
    }

    onChange = e => {
        var el = { ...this.state.el }
        el[e.target.name] = e.target.value        
        this.setState({ el });
    };

    onChangeCnpj = e => {
        e.target.value = cnpjMask(e.target.value)
        this.onChange(e)
        let cnpj = e.target.value
        if (cnpjValidation(cnpj)) {
            var numcnpj = cnpj.replace(/\D+/g, '')
        }
    };

    onSaveClick = (event) => {
        var repo = {}
        repo.commercial = {...this.state.el}
        repo.commercial.density = repo.commercial.density.replace(',', '.')
        repo.commercial = fixBlankProperties(repo.commercial)
        repo.products = []
        this.state.sps.map((item, index) => {
            var p = {}
            p.id = item.pr.id ?? ""
            p.ncm_general = item.pr.ncm_general
            p.concentration = item.pr.concentration
            p = fixBlankProperties(p)
            repo.products.push(p)
        })

        if (!this.state.el.id){
            this.setState({loading:true})
            this.props.addCommercialProduct(repo).then(() => {
                this.checkAfterSave()
                }
            )
            .catch(error => {
                this.setState({loading:false})
              }
            )
        }
        else{
            this.setState({loading:true})
            this.props.addCommercialProduct(repo, true).then(() => {
                this.checkAfterSave()
                }
            ).catch(error => {
                this.setState({loading:false})
              }
            )
        }
    };

    checkAfterSave = () => {
        this.setState({loading:false})
        if (this.props.isModalAddCommercialProduct)
            this.props.modalAddCommercialProduct(null)
        else
            this.props.history.push('/manage/commercialproducts');
    }

    onCancel = () => {
        this.checkAfterSave()
    }

    onChangeProductConcentration = (e, index, type='pr') => {
        var sps = this.state.sps
        var obj = {...sps[index][type]}
        obj.concentration = onlyNumber(e.target.value)
        sps[index][type] = obj
        this.setState({sps})
    }

    onChangeProduct = (e, index, type = 'pr', name = null, value=null) => {
        var sps = this.state.sps        
        var spsIndexed = sps[index]
        var obj = {...spsIndexed[type]}
        if (name){
            obj[name] = value
        }else{
            obj[e.target.name] = e.target.value         
        }        
        sps[index][type] = obj
        this.setState({sps})
    }

    onChangeNCMGeneral = (e, index) => {
        this.onChangeProduct(e, index)
        this.setState({loading:true})
        this.props.getNCMSynonymsByNCMGeneral(e.target.value).then(() => {
            this.setState({loading:false})

            const { ncmsynonyms } = this.props.ncmsynonymsReduc
            var ncmsynonyms_options = ncmsynonyms.map(c => {
                return <option key={c.id} value={c.id}>{c.name }</option>
            })
            this.onChangeProduct(e, index, 'pr', 'ncm_synonym', "")
            this.onChangeProduct(e, index, 'ncmsynonyms', 'options', ncmsynonyms_options)
        })
    }

    onRemoveSpFromReview = () => {
        var sps = this.state.sps
        sps.pop()
        this.setState({sps})
    }

    addSpToReview = () => {
        var sps = this.state.sps
        sps.push({
            ncmsynonyms: {options: []},
            pr:{
                ncm_general: "",
                concentration:"",
                enabled:true
            }
        })
        this.setState({sps})
    }

    render() {
        if (this.state.el.company)
            this.state.el.company = cnpjMask(this.state.el.company)

        const { ncmgenerals } = this.props.ncmgeneralsReduc

        let ncmgenerals_options = ncmgenerals.map(c => {
            return <option key={c.id} value={c.id}>{ c.name + ' ' + c.code }</option>
        })

        let i = 0
        return (
            <>
            <Loader loading={this.state.loading} />
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Cadastro de Produto Composto</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="AddCommercialProduct" noValidate validated={this.state.validated}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="commercial_name">
                                            <Form.Label className="font-weight-bold">Produto</Form.Label>
                                            <Form.Control type="text" name="commercial_name" placeholder="Nome comercial" value={this.state.el.commercial_name}
                                                onChange={this.onChange} maxLength={250} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="density">
                                            <ControlDensity onChangeDensity={this.onChangeDensity} value={this.state.el.density} />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group as={Col} md="4"> 
                                        <Form.Label className="font-weight-bold" >Ativo</Form.Label>
                                        <Form.Check id="enabled" name="enabled"
                                            className="mb-1 pl-0">
                                            <Form.Check.Label>
                                            <Form.Check.Input id="enabled" name="enabled" onChange={this.onChangeCheckbox}
                                                checked = {this.state.el.enabled}
                                                value = {this.state.el.enabled}
                                                type="checkbox"
                                            ></Form.Check.Input>
                                            <span className="form-check-sign"></span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>

                                    <Row>
                                        <h5 className="text-center text-secondary font-weight-bold"><b>Fórmula</b></h5> <hr></hr>
                                    </Row>
                                    {this.state.sps.map((row, index) => {
                                    return (
                                    <div key={i++}>
                                    <Row key={i++} className="mb-3">
                                        <Form.Group key={i++} as={Col} md="6">
                                            <Form.Label key={i++} className="font-weight-bold" >NCM</Form.Label>
                                            <Form.Control key={i++}
                                                as="select" required aria-label="NCM" name="ncm_general" onChange={e => this.onChangeNCMGeneral(e, index)} value={row.pr.ncm_general}>
                                                <option value="">--- SELECIONE ---</option>
                                                {ncmgenerals_options}
                                            </Form.Control>
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                        </Form.Group>                                       

                                        <Form.Group key={i++} as={Col} md="2" controlId="concentration">
                                            <ControlConcentration key={i++} value={row.pr.concentration} min={1} max={99}
                                                                onChangeConcentration={e => this.onChangeProduct(e, index)} />
                                        </Form.Group>

                                        {this.state.sps.length == index + 1 && <Col key={i++} md="1">
                                            <br/>
                                            <Button variant="danger" className="btn btn-primary" onClick={this.onRemoveSpFromReview}>
                                             <TrashFill color="red" />
                                            </Button> 
                                        </Col>}
                                    </Row>                                    
                                    </div>)
                                    }
                                    )}

                                    <Row>
                                    <Button className="btn btn-light active" variant="success" type="button" onClick={this.addSpToReview} >+1 Produto</Button>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button className="btn btn-light active" type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button>{' '}
                        <Button onClick={this.onCancel} className="btn btn-danger active">
                            Cancelar
                        </Button>
                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}

AddCommercialProduct.propTypes = {
    addCommercialProduct: PropTypes.func.isRequired,
    updateCommercialProduct: PropTypes.func.isRequired,
    getCommercialProduct: PropTypes.func.isRequired,
    commercialproductsReduc: PropTypes.object.isRequired,
    ncmgeneralsReduc: PropTypes.object.isRequired,
    ncmsynonymsReduc: PropTypes.object.isRequired,
    getNCMGenerals: PropTypes.func.isRequired,
    modalAddCommercialProduct: PropTypes.func.isRequired,
    getNCMSynonymsByNCMGeneral: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    commercialproductsReduc: state.commercialproductsReduc,
    ncmgeneralsReduc: state.ncmgeneralsReduc,
    ncmsynonymsReduc: state.ncmsynonymsReduc,
    isModalAddCommercialProduct: state.commercialproductsReduc.isModalAddCommercialProduct
});

export default connect(mapStateToProps, { addCommercialProduct, updateCommercialProduct, getCommercialProduct, getNCMGenerals, getNCMSynonymsByNCMGeneral, modalAddCommercialProduct })(withRouter(AddCommercialProduct));