import { GET_NCMGENERALS, GET_NCMGENERAL, ADD_NCMGENERAL, DELETE_NCMGENERAL, UPDATE_NCMGENERAL} from "./NCMGeneralTypes";

const initialState = {
  ncmgenerals: [],
  product:{}
};

export const ncmgeneralsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NCMGENERALS:
      return {
        ...state,
        ncmgenerals: action.payload
      };
    case GET_NCMGENERAL:
      return {
        ...state,
        product: action.payload
      };
    case ADD_NCMGENERAL:
      return {
        ...state,
        ncmgenerals: [...state.ncmgenerals, action.payload]
      };
    case DELETE_NCMGENERAL:
      return {
        ...state,
        ncmgenerals: state.ncmgenerals.filter((item, index) => item.id !== action.payload)
      };
    case UPDATE_NCMGENERAL:
      const updatedNCMGenerals = state.ncmgenerals.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
      return {
        ...state,
        ncmgenerals: updatedNCMGenerals
      };
    default:
      return state;
  }
};