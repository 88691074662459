import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { deleteNf, updateNf } from "./NfActions";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';
import { Authorized } from "utils/AuthUtil";
import ConfirmationModal from "components/utils/ConfirmationModal";

class Nf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false
    }
  }

  onDeleteClick = () => {
    const { nf } = this.props;
    this.props.deleteNf(nf.id);
  };
  render() {
    const { nf } = this.props;
    return (      
      <div className="btn-group">        
        <Link to={`/manage/nfs/edit/${nf.id}`} role="button" className="btn btn-primary">          
          <PencilFill color="royalblue" />
        </Link>
        {/* <Authorized>
          <ConfirmationModal title="Deseja inativar esta nota fiscal" function={this.onDeleteClick} />
        </Authorized> */}
      </div>      
    );
  }
}

Nf.propTypes = {
    nf: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteNf, updateNf })(
  withRouter(Nf)
);