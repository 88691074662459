import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_CALLS, GET_CALL, ADD_CALL, DELETE_CALL, UPDATE_CALL, UPLOAD_CALL } from "./CallsTypes";

export const getCalls = () => dispatch => {
  return axios
    .get("/api/v1/calls/")
    .then(response => {
      dispatch({
        type: GET_CALLS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addCall = call => dispatch => {
  call.id = 0
  return axios
    .post("/api/v1/calls/", call)
    .then(response => {
      dispatch({
        type: ADD_CALL,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const deleteCall = id => dispatch => {
  axios
    .delete(`/api/v1/calls/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_CALL,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getCall = id => dispatch => {
  return axios
    .get(`/api/v1/calls/${id}/`)
    .then(response => {
      dispatch({
        type: GET_CALL,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const uploadCollect = (file, hash, call, onUploadProgress) => dispatch => { 
  // call.id = 0
  let formData = new FormData();
  formData.append("file_path", file);
  call.hash = hash
  for (const i in call){    
    formData.append(i, call[i]);
  }
  return axios
    .post(`/api/v1/calls/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
    .then(response => {
      dispatch({
        type: UPLOAD_CALL,
        payload: response.data
      });
      toastOnSave("Carregamento do arquivo realizado com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
}

export const updateCall = (id, call) => dispatch => {
  return axios
    .patch(`/api/v1/calls/${id}/`, call)
    .then(response => {
      dispatch({
        type: UPDATE_CALL,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};
export const searchCall = description =>dispatch => {
  return axios
  .get(`/api/v1/calls/?description=${description}`)
  .then(response => {
    dispatch({
      type: GET_CALLS,
      // payload: response.data ? response.data.map(item => "call" in item ? item["call"] : null) : []
      payload: response.data
    });
    toastOnSave("Pequisa realizada")
  })
  .catch(error => {
    toastOnError(error);
  });
}
