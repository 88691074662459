import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { signupReducer } from "./components/signup/SignupReducer";
import { personsReducer } from "./components/persons/PersonReducer";
import { userPersonsReducer } from "./components/userPerson/UserPersonReducer"
import { loginReducer } from "./components/login/LoginReducer";
import { ufsReducer } from "./components/ufs/UfsReducer";
import { citiesReducer } from "./components/cities/CitiesReducer";
import { companiesReducer } from "./components/company/CompanyReducer"
import { suppliersReducer } from "./components/supplier/SupplierReducer"
import { originsReducer } from "./components/origin/OriginReducer"
import { acquirersReducer } from "./components/acquirer/AcquirerReducer"
import { destinationsReducer } from "./components/destination/DestinationReducer"
import { stocksReducer } from "./components/stock/StockReducer";
import { productsReducer } from "./components/product/ProductReducer";
import { ncmgeneralsReducer } from "./components/ncmgeneral/NCMGeneralReducer";
import { ncmsynonymsReducer } from "./components/ncmsynonym/NCMSynonymReducer";
import { commercialproductsReducer } from "./components/commecialproduct/CommercialProductReducer";
import { movementsReducer } from "./components/movement/MovementReducer";
import { fpFilesReducer } from "./components/fpfile/FpFileReducer";
import { callsReducer } from "./components/calls/CallsReducer";
import { nfsReducer } from "./components/nf/NfReducer"

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    signupReduc: signupReducer,
    personsReduc: personsReducer,    
    auth: loginReducer,
    userPersonsReduc: userPersonsReducer,
    companiesReduc: companiesReducer,
    suppliersReduc: suppliersReducer,
    originsReduc: originsReducer,
    acquirersReduc: acquirersReducer,
    destinationsReduc: destinationsReducer,
    productsReduc: productsReducer,
    stocksReduc: stocksReducer,
    ufsReduc: ufsReducer,
    citiesReduc: citiesReducer,    
    ncmgeneralsReduc: ncmgeneralsReducer,
    ncmsynonymsReduc: ncmsynonymsReducer,
    commercialproductsReduc: commercialproductsReducer,
    movementsReduc: movementsReducer,
    fpFilesReduc: fpFilesReducer,
    callsReduc:callsReducer,
    nfsReduc: nfsReducer,
  });

export default createRootReducer;