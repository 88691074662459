import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Stock from "./Stock"
import { setMask, cnpjMask, cnpjValidation, onlyNumber, convertDecimalPlaces} from "../../utils/mask"
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import {  formatDate, formatDateAndHours, formaDateSimple, dateFromIso } from "../../utils/Data";
import { header } from "../../utils/Datagrid";
import Loader from "components/utils/Loader"
import { getMovements } from "components/movement/MovementActions";
import {getNCMGenerals} from "components/ncmgeneral/NCMGeneralActions"

import {
  Card,
  Form,
  Row,
  Col,
  Button
} from "react-bootstrap";

class StockList extends Component {
  constructor(props) {
    super(props);
    this.dt = React.createRef();
    this.state = {
      options:{
        ncm_general:"",
        is_last:'True', 
        with_stock:'',
        agg:true
      },
      loading:false
    }
    this.props.getNCMGenerals()
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.props.getMovements(this.state.options).then(() => {
    })
    .catch((error) => {})
    .finally(() => {
      this.setState({ loading: false })
    });
  }

  cols_agg = [       
    { field: 'stocked_product__product__ncm_general__name', header: 'Nome' },
    { field: 'stocked_product__product__commercial_name', header: 'Nome Comercial' },
    { field: 'stocked_product__product__ncm_general__code', header: 'NCM' },
    { field: 'stocked_product__product__concentration', header: 'Conc (%)' },
    { field: 'stocked_product__product__density', header: 'Dens' },
    // { field: 'quantity', header: 'Quantidade (kg / L)' },
    { field: 'remaining_quantity', header: 'Estoque (kg / L)' },
    { field: 'max_reference_date', header: 'Data de Referência' },
  ];

  cols = [       
    { field: 'name_virtual', header: 'Nome' },
    { field: 'commercial_name_virtual', header: 'Nome Comercial' },
    { field: 'cnpj_supplier', header: 'Fornecedor' },
    { field: 'product.ncm_general_code', header: 'NCM' },
    { field: 'product.concentration', header: 'Conc (%)' },
    { field: 'product.density', header: 'Dens' },
    // { field: 'quantity', header: 'Quantidade (kg / L)' },
    { field: 'remaining_quantity', header: 'Estoque (kg / L)' },  
    { field: 'reference_date_virtual', header: 'Data de Referência' },
  ];


  exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  
  dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  alocBodyTemplate = (rowData) => {
    return <Stock key={rowData.id} stock={rowData} />
  }

  handleSubmit = () => {
    this.setState({ loading: true })
    this.props.getMovements(this.state.options).then(() => {
      this.setState({ loading: false })
    })
  }

  onChangeVisualizacao = e => {
    var options = { ...this.state.options }
    this.setState({ loading: true })
    options[e.target.name] = e.target.value === 'true' ? true : false
    this.props.getMovements(options).then(() => {
      var st = {...this.state}
      st.loading = false
      st.options = options
      this.setState(st);
    })
  }

  onChange = e => {
    var options = { ...this.state.options }
    this.setState({ loading: true })
    options[e.target.name] = e.target.value
    this.props.getMovements(options).then(() => {
      var st = {...this.state}
      st.loading = false
      st.options = options
      this.setState(st);
    })
  };

  render() {

    const { movements } = this.props.movementsReduc;
    let items = []
  
    if (movements.length > 0){
      if (!this.state.options.agg && movements[0].product){
        items = movements.map(movement => {
            var item = {...movement}
            item.enabled_virtual = item.enabled ? "Sim" : "Não"
            item.cnpj_supplier = setMask(item.cnpj_supplier) ?? item.cnpj_supplier
            item.name_virtual = item.product ? item.product.ncm_general_name : item.commercial_product.name
            item.commercial_name_virtual = item.product ? item.product.commercial_name : item.commercial_product.commercial_name
            item.reference_date_virtual = dateFromIso(item.reference_date)
            item.remaining_quantity = `${convertDecimalPlaces(item.remaining_quantity_kilos)} kg / ${convertDecimalPlaces(item.remaining_quantity_liters)} L`
            item.quantity = `${convertDecimalPlaces(item.quantity_kilos)} kg / ${convertDecimalPlaces(item.quantity_liters)} L`
            item.density = convertDecimalPlaces(item.density)
            item.product.concentration = item.product.concentration
          return item
        });      
      }else{
        items = movements.map(movement => {
          var item = {...movement}
          item.max_reference_date = dateFromIso(item.max_reference_date)
          item.remaining_quantity = `${convertDecimalPlaces(item.remaining_quantity_kilos)} kg / ${convertDecimalPlaces(item.remaining_quantity_liters)} L`
          item.quantity = `${convertDecimalPlaces(item.quantity_kilos)} kg / ${convertDecimalPlaces(item.quantity_liters)} L`
          item.density = convertDecimalPlaces(item.density)
          item.stocked_product__product__concentration = item.stocked_product__product__concentration
          return item
        })
      }
    }

    const { ncmgenerals } = this.props.ncmgeneralsReduc

    let ncmgenerals_options = ncmgenerals.map(c => {
      return <option key={c.id} value={c.id}>{ c.name + ' ' + c.code }</option>
    })
    
    return (
      <>
      <Loader loading={this.state.loading} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Cadastro de Estoque</Card.Title>
              <p className="card-category">
                Produtos em Estoque
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable ref={this.dt} value={items} header={(<div>
                  <Row className="mb-3"></Row>
                  <Row>
                    <Col md="10">
                      <Row >                
                      <Form.Group as={Col} md="4">
                          <Form.Label className="font-weight-bold" >NCM</Form.Label>
                          <Form.Control
                            as="select" aria-label="NCM" name="ncm_general" onChange={this.onChange} value={this.state.options.ncm_general}>
                            <option value="">--- SELECIONE ---</option>
                            {ncmgenerals_options}
                          </Form.Control>
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        </Form.Group> 
                        <Form.Group as={Col} md="4">
                          <Form.Label className="font-weight-bold" >Em Estoque</Form.Label>
                          <Form.Control
                            as="select" aria-label="with_stock" name="with_stock" onChange={this.onChange} value={this.state.options.with_stock}>
                            {[<option key={1} value={''}>--- SELECIONE ---</option>,
                              <option key={2} value={'True'}>Sim</option>,
                              <option key={3} value={'False'}>Não</option>]}
                          </Form.Control>
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        </Form.Group> 
                        {/* <Form.Group as={Col} md="4">
                          <Form.Label className="font-weight-bold" >Visualização</Form.Label>
                          <Form.Control
                            as="select" aria-label="agg" name="agg" onChange={this.onChangeVisualizacao} value={this.state.options.agg}>
                            {[<option key={1} value={true}>Agregada</option>,
                              <option key={2} value={false}>Analítica</option>]}
                          </Form.Control>
                          <Form.Control.Feedback>Ok</Form.Control.Feedback>
                        </Form.Group> */}
                      </Row>
                    </Col>
                    <Col >
                      {header(movements, "Pessoas", this.dt, this.exportColumns)}
                    </Col>
                  </Row>
                  </div>)}
                  tableStyle={{ minWidth: '50rem' }}
                  paginator rows={5}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      rowsPerPageOptions={[5, 10, 25, 50]}
                  emptyMessage="Não há produtos cadastradas"
                  
                  >
                  {!this.state.options.agg && this.cols.map((col, index) => (
                      <Column key={index} sortable field={col.field} header={col.header} />
                  ))}
                  {this.state.options.agg && this.cols_agg.map((col, index) => (
                      <Column key={index} sortable field={col.field} header={col.header} />
                  ))}
                </DataTable>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </>
    );
  }
}

StockList.propTypes = {
  getMovements: PropTypes.func.isRequired,
  movementsReduc: PropTypes.object.isRequired,
  ncmgeneralsReduc:PropTypes.object.isRequired,
  getNCMGenerals:PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  movementsReduc: state.movementsReduc,
  ncmgeneralsReduc: state.ncmgeneralsReduc,
});

export default connect(mapStateToProps, {
  getMovements,getNCMGenerals
})(withRouter(StockList));