import axios from "axios";
import { toastOnError, toastOnSave } from "../../utils/Utils";
import { GET_NCMGENERALS, GET_NCMGENERAL, ADD_NCMGENERAL, DELETE_NCMGENERAL, UPDATE_NCMGENERAL} from "./NCMGeneralTypes";
import { toast } from "react-toastify";

export const getNCMGenerals = () => dispatch => {
  axios
    .get("/api/v1/ncmgenerals/")
    .then(response => {      
      dispatch({
        type: GET_NCMGENERALS,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const addNCMGeneral = company => dispatch => {
  return axios
    .post("/api/v1/ncmgenerals/", company)
    .then(response => {
      dispatch({
        type: ADD_NCMGENERAL,
        payload: response.data
      });
      toastOnSave("Inclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};

export const deleteNCMGeneral = id => dispatch => {
  axios
    .delete(`/api/v1/ncmgenerals/${id}/`)
    .then(response => {
      dispatch({
        type: DELETE_NCMGENERAL,
        payload: id
      });
      toastOnSave("Exclusão realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const getNCMGeneral = id => dispatch => {
  axios
    .get(`/api/v1/ncmgenerals/${id}/`)
    .then(response => {
      dispatch({
        type: GET_NCMGENERAL,
        payload: response.data
      });
    })
    .catch(error => {
      toastOnError(error);
    });
};

export const updateNCMGeneral = (id, company) => dispatch => {
  return axios
    .patch(`/api/v1/ncmgenerals/${id}/`, company)
    .then(response => {
      dispatch({
        type: UPDATE_NCMGENERAL,
        payload: response.data
      });
      toastOnSave("Atualização realizada com sucesso")
    })
    .catch(error => {
      toastOnError(error);
      throw error
    });
};